* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #f8f8f8;
  font-family: 'Open Sans', sans-serif;
}

:root {
  --primary: #000d47;
  --black: #0c0c0c;
  --white: #f8f8f8;
  --gray: #c4c4c4;
}

::-webkit-scrollbar {
  width: 10px;
  background: var(--gray);
}

::-webkit-scrollbar-track:hover {
  background-color: var(--gray);
  width: 1rem;
}
   
::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  width: 1rem;
  cursor: pointer;
}
   
::-webkit-scrollbar-thumb:hover {
  background-color: var(--black);
}

.commonHeading {
  padding: 5rem 1.5rem 0 0;
  font-size: 2.5rem;
  font-weight: 700;
  width: 74%;
}